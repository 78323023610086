import React from "react"
import { Link } from "gatsby"
import {Container,Row,Col} from "react-bootstrap";
import Layout from "../components/layout"
import SEO from "../components/seo"
import CustomImage from "../components/imagesComponents/CustomImage";
import indexStyle from '../styles/index.module.css'

const background = {backgroundColor:'#F8F9F9',paddingTop:'50px',paddingBottom:'30px'}

const StateClearingPage = () => (
  <Layout>
    <SEO title="stateclearing" />
    <body className={indexStyle.font} >
    <Container style={background}>
      <Row>
        <Col sm={12} md={{span:10, offset:1}}>
          <div style={{textAlign:'justify'}}>
            <h3 className={indexStyle.title} style={{color:'#E9BF3C'}}>Estate Clearing</h3> 
            <p>A full or partial liquidation of an estate is often tied to important and emotional life changes.
            Timelines are critical in most circumstances. We offer a free no obligation consultation to get
            you the information you need to move forward. If you are considering an estate sale, an on-site
            review of the home is essential as estate sales are held in the home.</p>
            <CustomImage src={'stateclean1.jpg'} style={{minWidth:'300px'}}/><br/>
            <p>At the point of first meeting the goal is to understand what the estate inventory encompasses:
            the quantity and variety of goods, what items will be kept, and what you want to sell. That is the
            first step in determining if an estate sale is warranted. Household goods, like clothing, trend in
            and out of desirability and it takes someone with current experience to determine the
            marketability of the estate’s inventory. Don’t dispose of property beforehand so as to ensure
            nothing of value is thrown out since it is often a surprise what buyers consider treasure.
            Preparing for an estate sale involves sorting, organizing, pricing, researching special items,
            notifying interested collectors, and marketing the sale.</p>
            <p>We follow a tried and true process of clearing an estate from start to finish, but because every
            case is different, we customize each process. After the consultation you will be given a
            proposal, and if you approve, a contract will be signed. A broom clean after the sale is always
            included along with donation or disposal of all remaining items. An option, for a reasonable fee, is
            a deep cleaning service following the estate clearing. As always, respecting the lifetime of
            possessions is at the heart of our services.</p>
          </div>
        </Col>
      </Row>
    </Container>
    </body>
  </Layout>
)

export default StateClearingPage